import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageBody } from '@abyss/web/ui/PageBody';
import { Modal } from '@abyss/web/ui/Modal';
import { FileUpload } from '@abyss/web/ui/FileUpload';
import { TextInput } from '@abyss/web/ui/TextInput';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Table } from '@abyss/web/ui/Table';

import { options } from '@src/utils/constants';
import { read, utils, write } from 'xlsx';
import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button } from '@abyss/web/ui/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { hostServer, indicator } from '../../utils/constants';
import { UploadedFileHistory } from '../UploadedFileHistory';
import CompareComponent from '../Dashboard';
import { MapColumns } from '../MapColumns';
import { ReviewPopup } from '../ReviewPopup';
import { MessagePopup } from '../MessagePopup';

import { startLoader, showDialog, stopLoader } from '../../store/actions';
import { Loader } from '../Loader';

const BILLINGZIP = 'BILL_POSTALCODE';
const PLSVZIP = 'POSTALCODE';
const serverUrl = hostServer.dev;

export const LandingPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeAccountEmail = useSelector((state) => {
    return state.activeAccountEmail;
  });
  const activeAccountUserName = useSelector((state) => {
    return state.activeAccountUserName;
  });
  const selectedRowCounts = useSelector((state) => {
    return state?.selectedRowCount;
  });
  const [showCreateCompareModal, setShowCompareModal] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState('');

  const [headerRow, setHeaderRow] = useState(1);
  const [sheetJsonData, setSheetJsonData] = useState({});
  const [sheetColumns, setSheetColumns] = useState([]);
  const [displaySheetData, setDisplaySheetData] = useState(false);
  const [sheetDataHeaderRows, setSheetDataHeaderRows] = useState([]);
  const [sheetColumnsHeaderRow, setSheetColumnsHeaderRow] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [removePopup, setRemovepopup] = useState(false);
  const [openProcessingModal, setOpenProcessingModal] = useState(false);
  const [mappedData, setMappedData] = useState([]);
  const [isReview, setIsReview] = useState(false);
  const [deafultColumnList, setDeafultColumnList] = useState([]);
  const [selectedVal, setSelectedVal] = useState(selectedRowCounts || '1');
  const [rosterName, setRosterName] = useState('');
  const [successMessage, setDropDownValidation] = useState(false);
  const [errorMessage, setDataErrorMessage] = useState(false);
  const [tinValidation, setTinValidation] = useState(false);
  const [npiValidation, setNPIValidation] = useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [nameError, setRosterNameError] = useState(false);
  const checkDisable = !(sheetColumns && sheetColumns.length > 0);

  const objectArrayVal = useSelector((state) => {
    return state;
  });

  const backButtonClick = useSelector((state) => {
    return state?.backButtonStatus;
  });
  const loading = useSelector((state) => {
    return state.loading;
  });
  // const selectRowValues = (selectedValue) => {
  //   localStorage.setItem('Name', selectedValue);
  //   setSelectedVal(selectedValue);

  //   dispatch({
  //     type: 'SET_SELECTED_ROW__VALUES',
  //     value: selectedValue,
  //   });
  // };

  useEffect(() => {
    localStorage.setItem('selectHeader', 1);
  }, []);
  const fileTypesAccepted = {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
      '.xlsx',
      '.csv',
    ],
  };

  const onFileChangeHandler = (e) => {
    if (e == 0 || e == []) {
      alert('Please upload valid file!!');
      setUploadedFileName('');
    }
    const ext = e[0]?.name.split('.').pop();
    if (e) {
      if (ext !== 'csv' && ext !== 'xlsx' && ext !== 'xlx') {
        alert(
          'Only csv, xlsx & xlx file will be processed. Please upload valid file!!'
        );
        setUploadedFileName('');
      } else {
        // setShowError(false);
        // setShowToster(true);
        setLoading(true);
        dispatch(startLoader());
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;

          const workbook = read(data, { type: 'array' });
          // SetMainWorkBook(workbook);
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const lastColumnIndex = localStorage.getItem('selectHeader');

          console.log('headerRow===', lastColumnIndex);
          for (
            let selectIndex = 1;
            selectIndex < lastColumnIndex;
            selectIndex++
          ) {
            deleteRow(worksheet, 0);
          }

          const json = utils.sheet_to_json(worksheet, {
            raw: true,
            defval: null,
            blankrows: false,
          });

          setSheetJsonData(json);
          processJsonToDisplaySheetData(json);
          if (json && json.length === 0) {
            setTimeout(() => {
              //   setShowToster(false);
              //   setEmptyFileError(true);
              dispatch(stopLoader());
              setLoading(false);
            }, 5000);
            return;
          }

          const allKeys = Object.keys(json[0]);
          const cols = allKeys.map((item) => {
            return {
              key: item,
              name: item.trim().substring(0, 20),
            };
          });

          setSheetColumnsHeaderRow(cols);
          // console.log(cols);

          const colData = allKeys.map((item) => {
            return { value: item };
          });

          colData.sort((a, b) => {
            if (a.key < b.key) {
              return -1;
            }
            if (a.key > b.key) {
              return 1;
            }
            return 0;
          });

          setSheetColumns(colData);
          // console.log(cols);
          dispatch(stopLoader());
          setLoading(false);
        };
        reader.readAsArrayBuffer(e[0]);
      }
    }
  };
  const onReviewPopupClick = (e) => {
    e.preventDefault();

    setIsReview(true);
  };
  const processJsonToDisplaySheetData = (sheetData) => {
    const sliced = sheetData.slice(0, 20);
    setSheetDataHeaderRows(sliced);
  };
  function ec(r, c) {
    return utils.encode_cell({ r, c });
  }
  /* istanbul ignore next */
  function deleteRow(ws, row_index) {
    const variable = utils.decode_range(ws['!ref']);
    for (let R = row_index; R < variable.e.r; ++R) {
      for (let C = variable.s.c; C <= variable.e.c; ++C) {
        ws[ec(R, C)] = ws[ec(R + 1, C)];
      }
    }
    variable.e.r--;
    ws['!ref'] = utils.encode_range(variable.s, variable.e);
  }
  const currentDate = Date.now();
  const formatDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(currentDate);

  const updateMappedColumn = () => {
    navigate('/UpdateNewMappedColumnsNew');
  };
  const fetchcolumnsData = () => {
    axios
      .get(`${serverUrl}/fetchMappedcolumn`)
      .then((response) => {
        const responseData = response.data.results;
        if (responseData && responseData.length && responseData.length > 0) {
          console.log('hello data==== ', responseData);
          setMappedData(response.data.results);
          dispatch({
            type: 'FETCHED_SELECTED_ROW__VALUES',
            value: response.data.results,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  useEffect(() => {
    fetchcolumnsData();
  }, []);

  const compareColumns = () => {
    if (!uploadedFileName || uploadedFileName === '') {
      setRosterNameError(true);
      return;
    }

    setOpenProcessingModal(true);
    // navigate('/Dashboard');
    setShowCompareModal(false);
  };
  const insertMappedData = (MappedData) => {
    MappedData.forEach((item) => {
      if (
        typeof item.PROVIDERTIN === 'number' &&
        item.PROVIDERTIN.toString().length !== 9
      ) {
        item.PROVIDERTIN = item.PROVIDERTIN.toString().padStart(9, '0');
      }
    });

    axios
      .post(`${serverUrl}/saveColumnData`, { data: MappedData })
      .then((response) => {
        if (response?.data?.err) {
          fetchcolumnsData();
          setDataErrorMessage(true);
          setErrorNameMsg(response?.data?.err?.message);
        }
        if (response?.data?.results) {
          fetchcolumnsData();
          setDropDownValidation(true);
        }
      })
      .catch((err) => {});
  };

  const validationFunction = (finalData) => {
    let npiFlag = false;
    const regexForNpi = /^\d{10}$/;
    const findWrongNpi = finalData.find((o) => {
      return !regexForNpi.test(o.NPI);
    });
    if (findWrongNpi && Object.keys(findWrongNpi).length !== 0) {
      npiFlag = true;
    }
    if (npiFlag) {
      setNPIValidation(true);
    }
    return npiFlag;
  };
  const providerTinValidation = (finalData) => {
    let tinFlag = false;
    const regexForTin = /^\d+$/; // /^\d{9}$/;

    const findWrongTin = finalData.find((o) => {
      return !regexForTin.test(o.PROVIDERTIN);
    });
    if (findWrongTin && Object.keys(findWrongTin).length !== 0) {
      tinFlag = true;
    }
    if (tinFlag) {
      setTinValidation(true);
    }
    return tinFlag;
  };

  const savedMappedColums = (objectData) => {
    axios
      .post(`${serverUrl}/saveMappedColumn`, { data: objectData })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onMappingSubmit = () => {
    let selectedColumnList = objectArrayVal?.objectArray;
    if (backButtonClick) {
      selectedColumnList.length = 0;
    }

    selectedColumnList = [...selectedColumnList].reverse();
    let json = sheetJsonData;
    const obj = selectedColumnList;
    const payload = readDataFromExcel(obj, deafultColumnList);

    const newFinalMappedData = listToSaveDatabase(json);
    const finalJson = [];
    json = newFinalMappedData;
    sortedJsonData(json);
    for (let i = 0; i < json.length; i++) {
      finalJson.push(sortObject(json[i]));
    }

    const validationFlag = validationFunction(finalJson);
    const tinValidationCheck = providerTinValidation(finalJson);

    if (validationFlag || tinValidationCheck) return;
    savedMappedColums(payload);
    insertMappedData(finalJson);

    const newFile = utils.json_to_sheet(finalJson);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, newFile, 'myWorkSheet');
    const fileName = sheetFileName();
    const generatedFile = write(wb, {
      bookType: 'csv',
      bookSST: true,
      type: 'array',
    });
    const data = new Blob([generatedFile], {
      type: 'application/vnd.ms-excel',
    });

    data.name = `${fileName}.csv`;

    setLoading(true);
    dispatch(startLoader());
    dispatch({ type: 'SUBMIT_BUTTON_CLICKED', value: true });

    setTimeout(() => {
      setSheetColumns([]);
      dispatch(stopLoader());
      setLoading(false);
      setRosterName('');

      dispatch({
        type: 'SET_REVIEWED_ROW_VALUES',
        value: [],
      });
      dispatch({ type: 'FILE_UPLOAD_STATUS', value: false });
      localStorage.setItem('selectHeader', 1);
      setSelectedVal(1);
      localStorage.removeItem('rosterName');
    }, 8000);
    dispatch({
      type: 'SET_REVIEWED_ROW_VALUES',
      value: [],
    });
  };
  const sortedJsonData = (jsonData) => {
    const rosterFile = sheetFileName();
    jsonData.map((item) => {
      item.EMAIL = activeAccountEmail;
      item.ROSTERFILENAME = rosterFile;
      // item.CON_DETAIL_IND = indicatorVal;

      if (item.NPI) {
        item.NPI = parseInt(item.NPI);
      }
      if (item.PROVIDERTIN && /[,\-]/.test(item.PROVIDERTIN)) {
        const removeDashes = item.PROVIDERTIN.replace(/-/g, '');
        item.PROVIDERTIN = removeDashes;
      }
      if (item[PLSVZIP]) {
        item[PLSVZIP] = parseInt(item[PLSVZIP]);
      }
      if (item[BILLINGZIP]) {
        item[BILLINGZIP] = parseInt(item[BILLINGZIP]);
      }
    });

    return jsonData;
  };
  function sortObject(obj) {
    return Object.keys(obj)
      .sort((a, b) => {
        const aIsUpperCase = a.toUpperCase() === a;
        const bIsUpperCase = b.toUpperCase() === b;
        if (aIsUpperCase === bIsUpperCase) return a.localeCompare(b, 'es');
        if (aIsUpperCase) return -1;
        return 1;
      })
      .reduce((result, key) => {
        result[key] = obj[key];
        return result;
      }, {});
  }
  const handleClose = () => {
    /* istanbul ignore next */
    setIsReview(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    onMappingSubmit();
    setIsReview(false);
  };
  const handleReset = () => {
    localStorage.setItem('selectHeader', 1);
    setHeaderRow(1);
    setSheetColumns([]);
    setUploadedFileName('');
    setSheetJsonData([]);
    dispatch({ type: 'FILE_UPLOAD_STATUS', value: false });
    setRosterName('');
    dispatch({ type: 'FILE_BACK_BUTTON_STATUS', value: true });
    localStorage.removeItem('rosterName');
    dispatch({
      type: 'SET_REVIEWED_ROW_VALUES',
      value: [],
    });
    setOpenProcessingModal(false);
  };
  const listToSaveDatabase = (json) => {
    const finalListOfData = [];
    const finalMapping = objectArrayVal?.reviewedList;
    for (let j = 0; j < json.length; j++) {
      const newRow = {};

      for (let i = 0; i < finalMapping?.length; i++) {
        const definedColumnName = finalMapping[i].orignal; // defined column name
        const mappedColumnFromSheet = finalMapping[i].tobeUpdate; // sheet column name
        newRow[definedColumnName] = json[j][mappedColumnFromSheet];
      }
      finalListOfData.push(newRow);
    }

    return finalListOfData;
  };
  const findDefaultColumnList = () => {
    const defaultList = mappedData;
    const orgSheetColumns = sheetColumns;
    const finalDefColumnList = [];
    for (let j = 0; j < orgSheetColumns.length; j++) {
      for (let i = 0; i < defaultList.length; i++) {
        if (orgSheetColumns[j].value === defaultList[i].NEW_MAP_COL_NM) {
          finalDefColumnList.push({
            orignal: defaultList[i].DEFINED_COL_NM,
            tobeUpdate: defaultList[i].NEW_MAP_COL_NM,
          });
        }
      }
    }

    setDeafultColumnList(finalDefColumnList);
  };
  useEffect(() => {
    if (sheetColumns && sheetColumns.length && sheetColumns.length > 0) {
      // console.log('sheetColumns====', sheetColumns);
      findDefaultColumnList();
    }
  }, [sheetColumns]);

  const readDataFromExcel = (selectedList, predefinedList) => {
    predefinedList = JSON.stringify(predefinedList);
    let payload = [];
    for (let j = 0; j < selectedList.length; j++) {
      if (!predefinedList.includes(JSON.stringify(selectedList[j]))) {
        payload.push(selectedList[j]);
      }
    }

    payload = payload.filter((v, i, a) => {
      return (
        a.findIndex((v2) => {
          return v2.orignal === v.orignal;
        }) === i
      );
    });
    return payload;
  };
  const sheetFileName = () => {
    console.log('uploadedFileName====', uploadedFileName);
    const d = new Date();
    const formatedData = `${
      d.getMonth() + 1
    }-${d.getDate()}-${d.getFullYear()}_${d.getHours()}_${d.getMinutes()}_${d.getSeconds()}`;
    if (uploadedFileName) {
      setUploadedFileName(uploadedFileName.trim());
      setUploadedFileName(uploadedFileName.replace(/ /g, '_'));
      return `${uploadedFileName}${'_'}${formatedData}`;
    }
  };

  const handleCloseMSG = () => {
    /* istanbul ignore next */
    setDataErrorMessage(false);
    window.location.reload();
  };
  const handleAcknowledge = (event) => {
    event.preventDefault();
    setDataErrorMessage(false);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };
  const handleClosePopup = () => {
    /* istanbul ignore next */
    setNPIValidation(false);
    setTinValidation(false);
    // setAuthFlag(false);
    // window.location.reload();
  };
  // console.log('sheetJsonData====', sheetJsonData);
  return (
    <React.Fragment>
      <Backdrop data-testid="loading-1" open={isLoading}>
        <CircularProgress color="primary" />
      </Backdrop>
      <div
        style={{
          background: 'white',
        }}
      >
        <div
          style={{
            background: 'rgb(42, 87, 117)',
            height: '10px',
            width: '100%',
          }}
        />
        <div className="landing-page-header-wrapper">
          <div
            style={{
              width: '20%',
              paddingRight: '10px',
              // marginRight: '200px',
              // marginLeft: '100px',
              // marginTop: '5px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                // width: '50%',
                margin: '5px 5px 5px 20px',
              }}
            >
              <img
                style={
                  {
                    // position: 'absolute',
                  }
                }
                src="./UHG-Logo.png"
                alt="UHG Logo"
                width={50}
                height={50}
              />
            </div>
            <div
              style={{
                width: '50%',
                marginRight: '20px',
              }}
            >
              <a
                href="https://uhgazure.sharepoint.com/:p:/r/sites/PolarisProviderOperations1/Reporting_and_Technology/_layouts/15/Doc.aspx?sourcedoc=%7B20AD51BA-520F-4676-A6C4-AD94707592DA%7D&file=USP%20Provider%20Compare.pptx&action=edit&mobileredirect=true"
                target="_blank"
                style={{ color: 'rgb(0, 38, 119)' }}
                rel="noreferrer"
              >
                {' '}
                <img
                  style={{
                    // position: 'absolute',
                    display: 'flex',
                    cursor: 'pointer',
                  }}
                  src="./access-guide-link.png"
                  alt="access guide logo"
                  width={50}
                  height={50}
                />
              </a>
              <span
                style={{
                  fontSize: 'small',
                  color: 'rgb(0, 38, 119)',
                  fontWeight: '900',
                  display: 'flex',
                }}
              >
                User guide
              </span>
            </div>
          </div>
          <div
            style={{
              width: '85%',
              textAlign: 'center',
              marginRight: '5px',
              marginLeft: '5px',
              marginTop: '5px',
            }}
          >
            <h4>USP Provider Compare</h4>
          </div>
          <div
            style={{
              width: '5%',
              // marginRight: '100px',
              // marginLeft: '500px',
              marginTop: '5px',
              paddingRight: '200px',
            }}
          >
            <img
              style={{
                position: 'absolute',
              }}
              src="./UHC-logo.png"
              alt="UHC Logo"
              width={100}
              height={50}
            />
          </div>
        </div>
        <div
          style={{
            // textAlign: 'left',
            paddingLeft: '20px',
            display: 'flex',
            marginTop: '5px',
            alignItems: 'center',
          }}
        >
          <span>
            <img
              src="./queries.png"
              alt="Queries icon"
              width={30}
              height={30}
            />
          </span>
          <br />
          <span
            style={{
              color: '#002677',
              fontWeight: '900',
              marginLeft: '5px',
              fontSize: '12px',
            }}
          >
            <a
              href="https://uhgazure.sharepoint.com/sites/PolarisProviderOperations1/Reporting_and_Technology/Lists/USP%20Reporting%20%20Technology%20Intake%20Form/AllItems.aspx?viewpath=%2Fsites%2FPolarisProviderOperations1%2FReporting%5Fand%5FTechnology%2FLists%2FUSP%20Reporting%20%20Technology%20Intake%20Form%2FAllItems%2Easpx"
              target="_blank"
              style={{ color: 'rgb(0, 38, 119)' }}
              rel="noreferrer"
            >
              Any Queries/comments, please write to us
            </a>
          </span>
        </div>
        <div
          style={{
            textAlign: 'left',
            paddingLeft: '20px',
          }}
        >
          <span
            style={{
              color: '#002677',
              fontWeight: '900',
              fontSize: '14px',
            }}
          >
            Hello {activeAccountUserName} - Welcome to USP Provider Compare
          </span>
          <br />
          <span
            style={{
              color: '#002677',
              fontWeight: '900',
              fontSize: '13px',
            }}
          >
            Results emailed to : {activeAccountEmail}
          </span>
          {uploadedFileName && (
            <span
              style={{
                color: 'rgb(0, 38, 119)',
                marginLeft: '22%',
                fontWeight: '900',
                fontSize: '25px',
              }}
            >
              {uploadedFileName}
            </span>
          )}
          <span
            style={{
              float: 'right',
              fontWeight: '700',
              color: 'rgb(0, 38, 119)',
            }}
          >
            {formatDate}
          </span>
        </div>
        <div
          style={{
            margin: '8px 0px',
            background: 'rgb(204 243 247)',
            height: '15px',
            width: '100%',
          }}
        />
        <PageBody>
          <Loader open={loading} />
          {openProcessingModal ? (
            <React.Fragment>
              <MapColumns
                columns={sheetColumns}
                handleFinalSubmit={(e) => {
                  return onReviewPopupClick(e);
                }}
                handleReset={handleReset}
                mappedData={mappedData}
                uploadedFileName={uploadedFileName}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div
                style={{
                  width: '70%',
                  margin: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  height: '300px',
                }}
              >
                <section
                  style={{
                    cursor: 'pointer',
                    marginTop: '10px',
                  }}
                  onClick={() => {
                    setShowCompareModal(true);
                  }}
                >
                  <img
                    width="170"
                    height="170"
                    src="./comparet.png"
                    alt="Roster compare tool"
                  />
                  <img
                    max-width="500px"
                    width="70%"
                    height="200"
                    src="./displaybar.png"
                    alt="dislaybar"
                  />
                  <h6 style={{ fontSize: 'small' }}>Create Compare</h6>
                </section>
                <section
                  style={{
                    cursor: 'pointer',
                    marginLeft: '140px',
                  }}
                  onClick={() => {
                    updateMappedColumn();
                  }}
                >
                  <img
                    width="200"
                    height="200"
                    src="./updatecolumn.png"
                    alt="Update Column Mapping"
                  />
                  <img
                    max-width="500px"
                    width="70%"
                    height="200"
                    src="./displaybar.png"
                    alt="dislaybar"
                  />
                  <h6 style={{ fontSize: 'small' }}>Update Column Mapping</h6>
                </section>

                <section
                  style={{
                    cursor: 'pointer',
                    marginLeft: '140px',
                  }}
                  onClick={() => {
                    // console.log('hello click');
                    setShowHistory(true);
                  }}
                >
                  <img
                    width="170"
                    height="170"
                    src="./history.png"
                    alt="history"
                  />
                  <img
                    max-width="500px"
                    width="70%"
                    height="200"
                    src="./displaybar.png"
                    alt="dislaybar"
                  />
                  <h6 style={{ fontSize: 'small' }}>History</h6>
                </section>
              </div>
              <Modal
                title="Create Compare"
                isOpen={showCreateCompareModal}
                onClose={() => {
                  setShowCompareModal(false);
                  handleReset();
                }}
                css={{
                  'abyss-modal-content-title': {
                    fontSize: '24px!important',
                  },
                }}
              >
                <Modal.Section>
                  <FileUpload
                    maxMessage={false}
                    maxFiles={1}
                    label="Upload Roster:"
                    // isDisabled={false}
                    isDisabled={!checkDisable}
                    fileTypes={fileTypesAccepted || ''}
                    onChange={(e) => {
                      // console.log(e);
                      const fileName = e[0].name;
                      const fileNameSplit = fileName.split('.');
                      setUploadedFileName(fileNameSplit[0]);
                      onFileChangeHandler(e);
                    }}
                    css={{
                      'abyss-file-upload-body-container': {
                        width: '70% !important',
                        justifyContent: 'space-around',
                        flexDirection: 'row',
                        height: '60px!important',
                        // padding: '5px',
                      },
                    }}
                  />
                  <div
                    style={{
                      marginTop: '10px',
                    }}
                  >
                    <TextInput
                      css={{
                        'abyss-text-input-root': {
                          marginTop: '5px',
                          width: '60%',
                        },
                      }}
                      label="Roster filename:"
                      value={uploadedFileName}
                      onChange={(e) => {
                        setUploadedFileName(e.target.value);
                        setRosterNameError(false);
                      }}
                    />
                    {nameError && (
                      <span style={{ color: 'red' }}>
                        Please enter file name
                      </span>
                    )}
                    <SelectInput
                      isDisabled={
                        sheetJsonData &&
                        sheetJsonData.length &&
                        sheetJsonData.length > 0
                      }
                      label="Header Row:"
                      placeholder="Please select"
                      data-testid="selectInput"
                      isSearchable
                      value={headerRow}
                      css={{
                        'abyss-select-input-input-container': {
                          // border: '2px solid red !important',
                        },
                      }}
                      // isDisabled={!checkDisable}
                      width="200px"
                      onChange={(e) => {
                        /* istanbul ignore next */
                        console.log('eeeeeeeeeeee', e);
                        localStorage.setItem('selectHeader', e);
                        setHeaderRow(e);
                      }}
                      options={options}
                    />
                    <div
                      style={{
                        marginTop: '10px',
                        fontWeight: '800',
                        opacity: '0.8',
                      }}
                    >
                      <span>Sheet Data: </span>
                      <Link
                        onClick={() => {
                          setDisplaySheetData(true);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        Preview
                      </Link>

                      <Button
                        disabled={Object.keys(sheetJsonData).length === 0}
                        onClick={() => {
                          compareColumns();
                        }}
                        style={{ float: 'right', marginBottom: '5px' }}
                      >
                        Compare now
                      </Button>
                      <Modal
                        title="Sheet Data"
                        isOpen={displaySheetData}
                        onClose={() => {
                          setDisplaySheetData(false);
                        }}
                        size="full"
                      >
                        <Modal.Section>
                          <Table
                            title="Table Scroll"
                            columns={sheetColumnsHeaderRow}
                            rows={sheetDataHeaderRows}
                          />
                        </Modal.Section>
                      </Modal>
                    </div>
                  </div>
                </Modal.Section>
              </Modal>

              {showHistory && (
                <React.Fragment>
                  <UploadedFileHistory
                    showFileHistoryFlag={showHistory}
                    onCloseModal={() => {
                      setShowHistory(false);
                    }}
                    setIsOpen={setShowHistory}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {isReview && (
            <ReviewPopup
              open={isReview}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          )}
          {successMessage && (
            <MessagePopup
              open={successMessage}
              handleClose={handleCloseMSG}
              handleAcknowledge={handleAcknowledge}
              message="Submission Saved Successfully."
              errorName=""
              successCase
            />
          )}
          {errorMessage && (
            <MessagePopup
              open={errorMessage}
              handleClose={handleCloseMSG}
              handleAcknowledge={handleAcknowledge}
              message="Submission failed.  Please submit screenshot of error to R&T."
              errorName={errorNameMessage}
              errorCase="err"
            />
          )}
          {npiValidation && (
            <MessagePopup
              open={npiValidation}
              handleClose={handleClosePopup}
              handleAcknowledge={handleClosePopup}
              message="File Not Processed."
              errorName="NPI should be 10 digits numeric only. Please correct your file data."
              errorCase="err"
            />
          )}
          {tinValidation && (
            <MessagePopup
              open={tinValidation}
              handleClose={handleClosePopup}
              handleAcknowledge={handleClosePopup}
              message="File Not Processed."
              errorName="TIN should be Numeric only. Please correct your file data."
              errorCase="err"
            />
          )}
        </PageBody>
      </div>
    </React.Fragment>
  );
};
